import React, { useEffect, useState } from 'react';
import './Transactions.css'; // Import the CSS file
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminOrderList } from '../../../store/slices/adminSlices/adminOrderList/adminOrderAPI';
import { selectAdminOrderList } from '../../../store/slices/adminSlices/adminOrderList/adminOrderList';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { useNavigate } from 'react-router-dom';
import { selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';

const Transactions = () => {
    const dispatch = useDispatch();
    const orderList = useSelector(selectAdminOrderList);
    const data = orderList?.orders || [];
    const navigate = useNavigate()
    const adminLogin = useSelector(selectAdminLogin)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleStatus = (status) => {
        switch (status) {
            case 'ok':
                return 'statusOk'
            case 'hand':
                return 'statusHand'
            case 'yandex_balance':
                return 'statusYB'
            case 'balance':
                return 'statusBalance'

            default:
                break;
        }
    }
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const numberOfPages = Math.ceil(data.length / rowsPerPage);

    useEffect(() => {
        if (!orderList) {
            dispatch(fetchAdminOrderList('81f90dbe007d4364ad88c56f77319695'));
        }
    }, [orderList, dispatch]);

    
  useEffect(() => {
    dispatch(checkIsLoggedIn())
    setTimeout(() => {
      if (adminLogin) {
        navigate('/admin/transactions')
      }
    }, 200);
  }, []);


    const renderPagination = () => {
        const pagesToShow = 5;
        const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
        const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);

        const pages = [];
        if (startPage > 0) {
            pages.push(
                <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">1</Button>,
                startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Button
                    key={i}
                    onClick={() => handleChangePage(i)}
                    variant="outlined"
                    sx={{
                        mx: 0.5,
                        color: i === page ? 'white' : 'inherit',
                        borderColor: i === page ? '#FF5C00' : 'black',
                        backgroundColor: i === page ? '#FF5C00' : 'transparent',
                    }}
                >
                    {i + 1}
                </Button>
            );
        }

        if (endPage < numberOfPages - 1) {
            pages.push(
                endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
                <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">{numberOfPages}</Button>
            );
        }

        return pages;
    };

    function formatDateString(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${day}-${month}-${year}/${hours}:${minutes}`;
    }


    return (
        <>
            <PageHeader heading={'Գործարքներ'} hasFilter/>
            <TableContainer component={Paper}>
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ marginRight: 1 }}>Items per page:</Typography>
                        <FormControl variant="outlined" size="small">
                            <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Վարորդ</TableCell>
                            <TableCell>Հեռախոս</TableCell>
                            <TableCell>Իդրամ</TableCell>
                            <TableCell>Գումար</TableCell>
                            <TableCell>Բալանս</TableCell>
                            <TableCell>Վիճակ</TableCell>
                            <TableCell>Ավարտ</TableCell>
                            <TableCell>Ուղարկվել է</TableCell>
                            <TableCell>Գործողություն</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((el, index) => (
                            <TableRow key={index}>
                                <TableCell>{el.amount}</TableCell>
                                <TableCell>{el.phone}</TableCell>
                                <TableCell>{el.idram}</TableCell>
                                <TableCell>{el.price}</TableCell>
                                <TableCell>{el.balance}</TableCell>
                                <TableCell>
                                    <div className={`status ${handleStatus(el.status)}`}>{el.status}</div>
                                </TableCell>
                                <TableCell>{formatDateString(el.ended)}</TableCell>
                                <TableCell>{formatDateString(el.updated)}</TableCell>
                                <TableCell>
                                    <Button variant="contained" >Փոխանցել</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* Bottom Pagination */}
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ marginRight: 1 }}>Items per page:</Typography>
                        <FormControl variant="outlined" size="small">
                            <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};

export default Transactions;
