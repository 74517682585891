import { createSlice } from "@reduxjs/toolkit";
import { fetchDriverList } from "./adminDriverListApi";

const adminDriverList = createSlice({
    name: 'driverList',
    initialState: {
        data: null,
    },
    reducers:{

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriverList.fulfilled, (state, {payload}) => {
                state.data = { ...payload };
            })
    },
});

export const selectDriverList = (state) => state.driverList.data;
export const selectIsloggedIn = (state) => state.driverList.isLoggedIn;
export const driverListReducer = adminDriverList.reducer;
