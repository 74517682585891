import { createSlice } from "@reduxjs/toolkit";
import { fetchDriver, sendDriverSettings } from "./deiverAPI";

const driverSlice = createSlice({
    name: 'driver',
    initialState: {
        loading: false,
        islogin: false,
        settings: {
            park: '',
            percentage: 0,
            mode: 'hand'
        },
        data: null,
    },
    reducers:{
        startStopLoading: (state, {payload}) =>{
            return {
                ...state,
                loading: true
            } 
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriver.pending, (state) => {
                state.loading = true;
                state.rejected = false;
            })
            .addCase(fetchDriver.fulfilled, (state, { payload }) => {
                
                state.loading = false;
                state.islogin = true;                
                state.data = payload;
                state.settings ={
                    park: "",
                    percentage: payload.percentage * 100,
                    mode: payload.transfer_allowed ? "auto" : 'hand'
                }
            })
            .addCase(fetchDriver.rejected, (state, { payload, error }) => {
                state.islogin = false
                state.loading = false;
            })
            
            .addCase(sendDriverSettings.fulfilled, (state, {payload}) =>{
                state.settings = payload
            })
    },
});

export const selectDriver = (state) => state.driver;
export const {startStopLoading} = driverSlice.actions
export const driverReducer = driverSlice.reducer;
