import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

export const sendAdminPassword = createAsyncThunk(
    'adminLogin/sendAdminPassword',
    async function({username,password}) {
            
            const response = await axios.post(`https://api.diamondpay.am/api/login`, {
                    username,
                    password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);
export const adminLogout = createAsyncThunk(
    'adminLogin/adminLogout',
    async function() {
             await axios.get(`https://api.diamondpay.am/api/logout`)
        }
);

export const checkIsLoggedIn = createAsyncThunk(
    'adminLogin/checkIsLoggedIn',
    async function() {
            const response = await axios.get(`https://api.diamondpay.am/api/park`);
            const data = response.data;
            return data;
        }
);