import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

const getFirstDayOfCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Adding 1 since months are zero-indexed
    const day = '01'; // First day of the month
  
    return `${year}-${month}-${day}`;
  };
  
export const fetchAdminOrderList = createAsyncThunk(
    'adminOrderList/fetchAdminOrderList',
    async function (park_id) {
        console.log(getFirstDayOfCurrentMonth());
        
        const response = await axios.get(`https://api.diamondpay.am/api/park/orders/list`, {
            params: {
                park_id,
                start: getFirstDayOfCurrentMonth()
            },
            headers: {
                'Content-Type': 'application/json',
            }

        });

        const data = response.data;
        console.log(data);
        return data;
    }
);
