import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import './Header.css';
import { adminLogout } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const notificationRef = useRef(null); // Ref to track the notification element

  const handleAdminLogout = () => {
    dispatch(adminLogout());
    navigate('login')
  };

  const handleNotification = () => {
    setNotification(!notification);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setNotification(false); // Close the notification if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationRef]);

  return (
    <div className='Header'>
      <div className="HeaderUserInfo">
        <div
          ref={notificationRef} // Attach the ref to the notification section
          className={notification ? 'showNotification notificationSection' : 'notificationSection'}
        >
          <div className="notification">
            {/* Notification content here */}
          </div>
        </div>
        <div onClick={handleNotification} className="HeaderNotification">
          <i className="fa-regular fa-bell"></i>
          <div className="notDot">8</div>
        </div>
        <div className="UserInfo">
          <i className="fa-solid fa-user"></i>
        </div>
        <span>Diamond</span>
        <span onClick={handleAdminLogout} className='logoutBtn'>
          <i className="fa-solid fa-right-from-bracket"></i>
        </span>
      </div>
    </div>
  );
};

export default Header;
