import { createSlice } from "@reduxjs/toolkit";
import { fetchAdminOrderList } from "./adminOrderAPI";

const adminOrderList = createSlice({
    name: 'adminOrderList',
    initialState: null,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminOrderList.fulfilled, (state, {payload}) => {
                console.log('slice');
                
                return payload
            })
    },
});
export const selectAdminOrderList = (state) => state.adminOrderList
export const adminOrderListReducer = adminOrderList.reducer;
