import { createSlice } from "@reduxjs/toolkit";
import { sendAdminTransfer } from "./adminTransferAPI";

const adminTransferSlice = createSlice({
    name: 'adminTransfer',
    initialState: false,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminTransfer.fulfilled, (state, {payload}) => {
                console.log("success");
                return state
            })
    },
});
export const selectAdminLogin = (state) => state
export const adminTransferReducer = adminTransferSlice.reducer;
