import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Logo from '../../../sharedComponents/Logo';

const Sidebar = () => {

  const navigate = useNavigate()
  const location = useLocation()
  
  const logoClick =()=>{
      navigate('dashboard')
  }
  return (
    <div className='Sidebar'>

      <div onClick={logoClick} className="SidebarHeading">
        <Logo color='#FF5C00'/>
        <h1>Diamond Pay</h1>
      </div>
      <div className="SidebarCategories">
        <h3>Գլխավոր</h3>
        <ul>
          <NavLink to='dashboard' className={location.pathname === '/admin' ? 'active Navlink' : 'Navlink'}>
            <li><i className="fa-solid fa-chart-line"></i>Հաշվետվություն</li>
          </NavLink>
          <NavLink to='transactions' className={'Navlink'}>
            <li><i className="fa-solid fa-money-bill-transfer"></i>Գործարքներ</li>
          </NavLink>
          <NavLink to='transfers' className={'Navlink'}>
            <li><i className="fa-solid fa-right-left"></i>Փոխանցում</li>
          </NavLink>
          <NavLink to='drivers' className={'Navlink'}>
            <li><i className="fa-solid fa-users"></i>Վարորդներ</li>
          </NavLink>
        </ul>
        <div className="SidebarSettings">
          <span><i className="fa-solid fa-gears"></i>Կարգավորումներ</span>
        </div>
      </div>
      
    </div>
  );
}

export default Sidebar;
