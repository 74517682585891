import { useDispatch, useSelector } from "react-redux";
import { sendAdminTransfer } from "../../../store/slices/adminSlices/adminTransferSlice/adminTransferAPI";
import "./Transfers.css"
import { useEffect, useState } from 'react'
import { selectDriverList } from "../../../store/slices/adminSlices/adminDriverList/adminDriverList";
import { checkIsLoggedIn } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { selectAdminLogin } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import { useNavigate } from "react-router-dom";
const Transfers = () => {
    const dispatch = useDispatch()
    const drivers = useSelector(selectDriverList)
    const adminLogin = useSelector(selectAdminLogin)
    const navigate = useNavigate()
    let driverId = ''
    const [inputs, setInputs] = useState({
        driver: '',
        amount: ''
    });

    const handleChange = (e) => {

        const { name, value } = e.target;
        setInputs({

            ...inputs,
            [name]: value
        });
    };
    const handleTransfer = (e)=>{
        e.preventDefault()
        driverId = drivers.drivers.find(el=> el.phone === inputs.driver).driver_id
        
        dispatch(sendAdminTransfer({amount: inputs.amount, driver_id: driverId, park_id:'81f90dbe007d4364ad88c56f77319695'}))
        setInputs({
            driver: '',
            amount: ''
        })
    }
       
  useEffect(() => {
    dispatch(checkIsLoggedIn())
    setTimeout(() => {
      if (adminLogin) {
        navigate('/admin/transfers')
      }
    }, 200);
  }, []);

    return (
        <div className='Transfers'>
            <div className="form">
                <span className='TransfersHeading'>Փոխանցել Իդրամին</span>
                <form >
                    <div className="inputContainer">
                        <input 
                            type="text" 
                            onChange={handleChange} 
                            value={inputs.driver} 
                            name="driver" 
                            
                        />
                        <span  className={inputs.driver ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>Վարորդ</span>
                    </div>
                    <div className="inputContainer">
                        <input 
                            type="text" 
                            onChange={handleChange} 
                            name="amount"
                            value={inputs.amount}
                         />
                        <span className={inputs.amount ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>Գումար</span>
                    </div>
                    <button onClick={(e) =>handleTransfer(e)}>Ուղարկել</button>
                </form>
            </div>
        </div>
    );
}

export default Transfers;
