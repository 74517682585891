import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AdminApp from './admin/adminApp';
import DriverApp from './driver/driverApp';
   

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/admin/*" element={<AdminApp />} />
          <Route path="/*" element={<DriverApp />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
