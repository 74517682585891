import React, { useState, useEffect, useRef } from 'react';
import './PageHeader.css';

const PageHeader = ({ heading, hasFilter }) => {
    const [isFilterActive, setFilterActive] = useState(false);
    const filterRef = useRef(null); 
    const overlayRef = useRef(null); 
    const pageHeaderRef = useRef(null); 

    const toggleFilter = () => {
        setFilterActive(!isFilterActive);
    };

    const handleOverlayClick = () => {
        setFilterActive(false);
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target) &&
            pageHeaderRef.current && !pageHeaderRef.current.contains(event.target)) {
            setFilterActive(false); 
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className='PageHeader' ref={pageHeaderRef}>
            <h2>{heading}</h2>
            {hasFilter && (
                <button className='FilterBtn' onClick={toggleFilter}>
                    <i className="fa-solid fa-filter"></i> Ֆիլտրեր
                </button>
            )}

            <div 
                className={`overlay ${isFilterActive ? 'filterDim' : ''}`} 
                ref={overlayRef} 
                onClick={handleOverlayClick} 
            ></div>

            <div
                className={`filterSection ${isFilterActive ? 'activeFilterSection' : ''}`}
                ref={filterRef}
            >
                <h3>Ֆիլտրեր</h3>
                <form action="">
                    <input type="text" />
                    <input type="text" />
                </form>
            </div>
        </div>
    );
}

export default PageHeader;
