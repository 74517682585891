import React, { useEffect, useState } from 'react';
import './DriversPage.css'; // Import the CSS file
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDriverList } from '../../../store/slices/adminSlices/adminDriverList/adminDriverListApi';
import { selectDriverList } from '../../../store/slices/adminSlices/adminDriverList/adminDriverList';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';

const DriversPage = () => {

  const dispatch = useDispatch();
  const driverList = useSelector(selectDriverList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const adminLogin = useSelector(selectAdminLogin);
  const navigate = useNavigate();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = driverList?.drivers

  const numberOfPages = Math.ceil(driverList?.amount / rowsPerPage);

  useEffect(() => {
    const offset = page * rowsPerPage; // Calculate offset based on page and rowsPerPage
    dispatch(fetchDriverList({ park_id: '81f90dbe007d4364ad88c56f77319695', limit: rowsPerPage, offset: offset }));
  }, [page, rowsPerPage, dispatch]);

  useEffect(() => {
    dispatch(checkIsLoggedIn());
    setTimeout(() => {
      if (adminLogin) {
        navigate('/admin/drivers');
      }
    }, 200);
  }, []);

  const renderPagination = () => {
    const pagesToShow = 5; 
    const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
    const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);

    const pages = [];

    if (startPage > 0) {
      pages.push(
        <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">
          1
        </Button>,
        startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => handleChangePage(i)}
          variant="outlined"
          sx={{
            mx: 0.5,
            color: i === page ? 'white' : 'inherit',
            borderColor: i === page ? '#FF5C00' : 'black',
            backgroundColor: i === page ? '#FF5C00' : 'transparent',
          }}
        >
          {i + 1}
        </Button>
      );
    }

    if (endPage < numberOfPages - 1) {
      pages.push(
        endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
        <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">
          {numberOfPages}
        </Button>
      );
    }

    return pages;
  };

  return (
    <>
      <PageHeader heading={'Վարորդներ'} hasFilter />
      <TableContainer component={Paper}>
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ marginRight: 1 }}>Items per page:</Typography>
            <FormControl variant="outlined" size="small">
              <Select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
            <Button
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              sx={{ color: '#FF5C00' }}
            >
              &lt;
            </Button>
            {renderPagination()}
            <Button
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= numberOfPages - 1}
              sx={{ color: '#FF5C00' }}
            >
              &gt;
            </Button>
          </Box>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Idram</TableCell>
              <TableCell>Percentage</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Mode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((driver, index) => (
              <TableRow key={index}>
                <TableCell>{driver.name}</TableCell>
                <TableCell>{driver.balance}</TableCell>
                <TableCell>{driver.idram}</TableCell>
                <TableCell>{driver.percentage * 100}</TableCell>
                <TableCell>{driver.phone}</TableCell>
                <TableCell>{driver.transfer_allowed ? "Auto" : "Manual"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
    </>
  );
};

export default DriversPage;
