import { createSlice } from "@reduxjs/toolkit";
import { adminLogout, checkIsLoggedIn, sendAdminPassword } from "./adminLoginAPI";

const adminLoginSlice = createSlice({
    name: 'adminLogin',
    initialState: null,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminPassword.fulfilled, (state, {payload}) => {
                return payload
            })
            .addCase(adminLogout.fulfilled, (state, {payload})=>{
                return null
            })
            .addCase(checkIsLoggedIn.fulfilled,(state, {payload})=>{
                return payload
            })
    },
});
export const selectAdminLogin = (state) => state.adminLogin
export const adminLoginReducer = adminLoginSlice.reducer;
