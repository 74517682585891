import { useEffect, useState } from 'react';
import Logo from '../../../sharedComponents/Logo';
import './AdminLogin.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsLoggedIn, sendAdminPassword } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const [sectionPos, setSectionPos] = useState(false);
    const [loginInputs, setLoginInputs] = useState({ name: '', password: '' });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const adminLogin = useSelector(selectAdminLogin)
    const handlePos = (payload) => {
        setSectionPos(payload);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(sendAdminPassword({ username: loginInputs.name, password: loginInputs.password }));
    };
    return (
        <div className='AdminLogin'>
            <div className="AdminLoginSection">
                <div className="LoginSection">
                    <form className='LoginSectionForm'>
                        <input type="text" />
                        <input type="password" />
                        <button className='LoginSubmitBtn'>Մուտք</button>
                    </form>
                </div> 
                <div className={sectionPos ? 'DiamondPaySection DiamondPaySectionRight' : 'DiamondPaySection'}>
                    <div className="DiamondPayBtnSection">
                        <button className={sectionPos ? "AdminLogRegBtn AdminLogRegBtnActive" : 'AdminLogRegBtn'} onClick={() => handlePos(true)}>Գրանցվել</button>
                        <button className={sectionPos ? "AdminLogRegBtn " : 'AdminLogRegBtn AdminLogRegBtnActive'} onClick={() => handlePos(false)}>Մուտք</button>
                    </div>
                    <div className="DiamondPayLogoSection">
                        <div className="DiamondPayLogoContainer">
                            <Logo size='45px' color='#FF5C00' /> 
                            <h1>Diamond Pay</h1>
                        </div>
                        <h2>Բարի Գալուստ</h2>
                    </div>
                </div>
                <div className="LoginSection">
                    <h3>Մուտք գործեք Diamond Pay</h3>
                    <form className='LoginSectionForm' onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder='Ծածկանուն'
                            value={loginInputs.name}
                            onChange={handleInputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder='Ծածկագիր'
                            value={loginInputs.password}
                            onChange={handleInputChange}
                        />
                        <button type="submit" className='LoginSubmitBtn'>Մուտք</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
