import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';

export const fetchDriver = createAsyncThunk(
    'driver/fetchDriver',
    async function(phone) {
        
        const response = await axios.get(`https://api.diamondpay.am/api/driver`, {
            params: { phone }, 
            headers: {
                'Content-Type': 'application/json', 
            },
        });
        
        const data = response.data;
        console.log(data);
        return data;
    }
);

export const sendDriverSettings = createAsyncThunk(
    'driver/sendDriverSettings',
    async function({driver_id, percentage, hand_mode}) {
        
        const response = await axios.post(`https://api.diamondpay.am/api/driver`, 
            { 
                driver_id,
                percentage,
                hand_mode
            }, 
            {
                headers: {
                    'Content-Type': 'application/json', 
                },
                withCredentials: true  
            }
        );
        
        const data = response.data;
        
        return data;
    }
);