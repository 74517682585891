import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';

const getFirstDayOfCurrentMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    return firstDay.toISOString().split('T')[0];
};

export const 
fetchDriverOrders = createAsyncThunk(
    'driverOrders/fetchDriverOrders',
    async function (driver_id) {
        const response = await axios.get(`https://api.diamondpay.am/api/driver/order/list`, {
            params: {
                driver_id: driver_id,
                start: getFirstDayOfCurrentMonth()
            },
            headers: {
                'Content-Type': 'application/json',
            }

        });

        const data = response.data;
        return data;
    }
);
