import { useEffect, useState } from 'react';
import CircularProgress from '../../../sharedComponents/CircularProgres/CircularProgres';
import LiveChart from '../../../sharedComponents/LiveChart/LiveChart';
import './Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardSlice';
import { fetchDashboard } from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardAPI';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const dispatch = useDispatch();
    const reportData = useSelector(selectDashboard);
    const report = reportData?.data;
    const adminLogin = useSelector(selectAdminLogin)
    const navigate = useNavigate()
    const [chart, setChart] = useState([]);
  

    useEffect(() => {
        if (report) {
            const chartData = Object.keys(report?.days).map(el => {
                return {
                    day: `${parseInt(el.split('-')[2], 10)}`, 
                    price: report?.days[el] 
                };
            });
            setChart(chartData); 
        }
    }, [report]); 
    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return 0;
    }

    function graphPercent(x, y) {
        if (x && y) {
            return ((y / x) * 100).toFixed(1);
        }
        return 0;
    }

    const percent = report ? Math.round(report?.ok_total/200) : 0;

    useEffect(() => {
        if (!report) {
            dispatch(fetchDashboard('81f90dbe007d4364ad88c56f77319695'));
        }
    }, [report, dispatch]);
    useEffect(() => {
        dispatch(checkIsLoggedIn())
        setTimeout(() => {
            if (adminLogin) {
                navigate('/admin/dashboard')
            }
        }, 200);
    }, []);

    if (reportData.isLoading) {
    return (
        <div className="DashboardLoading">
            <div className='animated-svg'>
                <svg fill="white" width="50px" height="50px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.835 9.233l-4.371-8.358c-0.255-0.487-0.915-0.886-1.464-0.886h-10.060c-0.011-0.001-0.022-0.003-0.033-0.004-0.009 0-0.018 0.003-0.027 0.004h-9.88c-0.55 0-1.211 0.398-1.47 0.883l-4.359 8.197c-0.259 0.486-0.207 1.248 0.113 1.696l15.001 20.911c0.161 0.224 0.375 0.338 0.588 0.338 0.212 0 0.424-0.11 0.587-0.331l15.247-20.758c0.325-0.444 0.383-1.204 0.128-1.691zM29.449 8.988h-5.358l2.146-6.144zM17.979 1.99h6.436l-1.997 5.716zM20.882 8.988h-9.301l4.396-6.316zM9.809 8.034l-2.006-6.044h6.213zM21.273 10.988l-5.376 15.392-5.108-15.392h10.484zM13.654 25.971l-10.748-14.983h5.776zM23.392 10.988h5.787l-11.030 15.018zM5.89 2.575l2.128 6.413h-5.539z"></path>
                </svg>
            </div>
        </div>
    );
    }

    return (
        <div className="Dashboard">
            {<PageHeader heading={'Հաշվետվություն'} hasFilter/>}
            
            <div className="DashboardMainInfo">
                <div className="DashboardBadges">
                    <h3>Կատարված</h3>
                    <span>֏{formatNumber(report?.total) || 0}</span>
                    <span className='badgesSecondaryInfo'>Քանակ։ {formatNumber(report?.amount) || 0}</span>
                    <i className="fa-regular fa-circle-check badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Փոխանցված</h3>
                    <span>֏{formatNumber(report?.ok_total) || 0}</span>
                    <span className='badgesSecondaryInfo'>Քանակ։ {formatNumber(report?.ok_amount) || 0}</span>
                    <i className="fa-solid fa-money-bill-transfer badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Diamond Pay</h3>
                    <span>֏{formatNumber(percent) || 0}</span>
                    <i className="fa-solid fa-percent badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Ակտիվ վարորդներ</h3>
                    <span className='badgesSecondaryInfo activeDrivers'>{report?.active_count}</span>
                    <i className="fa-solid fa-bolt badgesIcons"></i>
                </div>
            </div>
            <div className="DashboardGraphs">
                <div className="GeneralStatistics">
                    <LiveChart chartData={chart}/>
                </div>
                <div className="RatioGraph">
                    <CircularProgress percent={graphPercent(report?.total, report?.ok_total) || 0} text='' borderR='' />
                    <div className="ProgressInfo">
                        <div className="InfoColors1">
                            <span className='ProgressInfoColor1'></span>
                            <span>Փոխանցված</span>
                        </div>
                        <div className="InfoColors1AddInfo">
                            <h2>Coming soon</h2>
                        </div>
                        <div className="InfoColors2">
                            <span className='ProgressInfoColor2'></span>
                            <span>Չեղարկված</span>
                        </div>
                        <div className="InfoColors2AddInfo">
                            <h2>Coming soon</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
