import Transactions from "./components/Transactions";
import Transfers from "./components/Transfers";
import Dashboard from "./components/Dashboard";
import Homewrap from './pages/Homewrap/';
import { Routes, Route } from 'react-router-dom'; 
import './adminApp.css';
import DriversPage from "./components/DriversPage";
import AdminLogin from "./components/AdminLogin";

function AdminApp() {

  return (
    <div className="AdminApp">
      <Routes>
        <Route path="login" element={<AdminLogin/>}/>
        <Route path="/" element={<Homewrap />}> 
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="transfers" element={<Transfers />} />
            <Route path="drivers" element={<DriversPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AdminApp;
